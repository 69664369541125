import vueCustomScrollbar from 'vue-custom-scrollbar'
import _ from 'lodash'
import Multiselect from '../../common/forms/MultiSelect.vue'
import Slider from '../../common/forms/Slider.vue'
import Switcher from '../../common/forms/Switcher.vue'
import VueSlider from 'vue-slider-component'
import Siren from './filtres_composants/parcelles_proprietaires.vue'
import Loading from '../../common/ui/loading_inex.vue'

export default {
	data() {
		return {
			user_filters: {},
			area_limit_ok: false,
			slider: undefined
		}
	},
	props:{
		params: {},
		reinit_call: 0,
		btn_reinit: true,
		menu_index: null
	},
	created(){
		//AS--> Charge les filtres iNex
		this.$store.dispatch('loadApiInexFilters')

		//AS--> Fixe le area_limit s'il est précisé dans les paramètres
		if(this.params && this.params.area_limit) this.$store.commit('PARCELLE_PARCELLES_AREA_LIMIT', this.params.area_limit);
	},
	watch:{
		"$store.state.ui.map_zone_google": function(after, before){
			// if(after.areaActif <= this.$store.state.parcelle.parcelles_area_limit){
				if(this.area_limit_ok == false) this.area_limit_ok = true
				if(this.$store.state.parcelle.menu_parcelles_affiche) this.$store.dispatch('loadParcelles')
				//AS--> Charge les filtres iNex
				this.$store.dispatch('loadApiInexFilters')

			// }else{
			// 	if(this.area_limit_ok == true) this.area_limit_ok = false
			// 	if(this.$store.state.parcelle.parcelles_geojson != false) this.$store.commit('PARCELLE_PARCELLES_GEOJSON', false);
			// }
		},
		reinit_call(){
			this.reset_filters()
		},
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
			if(before[this.menu_index]?.selectAll_index != after[this.menu_index]?.selectAll_index) this.toggle_filters()
		}
	},
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		},
		update_filters(){
			//AS--> Retire les valeur null
			let filters = _.cloneDeep(this.user_filters)
			_.forEach(filters, function(val, key){
				if(val === null) delete filters[key]
			})
			this.user_filters = filters

			//AS--> Envoi les filtres nettoyés au store
			this.$store.commit('PARCELLE_MENU_PARCELLES_FILTRES', this.user_filters);

			//AS--> Reload les parcelles
			if(this.$store.state.parcelle.menu_parcelles_affiche) this.$store.dispatch('loadParcelles')
		},
		getComponentType(type){
			if(type == 'select') return 'multiselect'
			if(type == 'numeric') return 'slider'
			if(type == 'switch') return 'switcher'
			return type
		},
		reset_filters(){
			this.user_filters = {}
			this.slider = 15
			this.updateOpacity(this.slider)
		},
		toggle_filters(){
			if(this.$store.state.parcelle.menu_parcelles_affiche){
				this.desactive_filters()
			}else{
				this.active_filters()
			}
		},
		active_filters(){
			this.$store.commit('PARCELLE_MENU_PARCELLES_AFFICHE', true)
			this.slider = 15
			this.updateOpacity(this.slider)
			if(this.area_limit_ok) this.$store.dispatch('loadParcelles')
		},
		desactive_filters(){
			this.$store.commit('PARCELLE_MENU_PARCELLES_AFFICHE', false)
			this.$store.commit('PARCELLE_PARCELLES_GEOJSON', false)
		},
		sliderChange(){
			let self = this
			let opacity = typeof this.slider == 'undefined' ? null : this.slider
			this.updateOpacity(this.slider)
		},
		updateOpacity(val){
			this.$store.dispatch('setParcellesOpacity', val)
		}
	},
	computed:{
		filtres(){
			if(this.$store.state.filters.inexapi_filters && this.$store.state.filters.inexapi_filters['parcelles'] && this.$store.state.filters.inexapi_filters['parcelles'].length){
				return this.$store.state.filters.inexapi_filters['parcelles']
			}
			return []
		}
	},
	components: {
    	vueCustomScrollbar,
    	Multiselect,
    	Slider,
    	Switcher,
    	VueSlider,
		Siren,
		Loading
	}
}